<template>
    <div class="div"><h1>Gestion de Présence</h1></div>
</template>
<script>
export default {
    setup() {
        
    },
    data:{
        
    }
}
</script>